'use client'

import { useState } from 'react'
import Link from 'next/link'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { navigation } from '@/config/navigation'
import { Logo } from '@/components/Logo'
import { LanguageSwitcher } from '@/components/LanguageSwitcher'
import { CommonLangProps } from '@/types/page'
import { useTranslation } from '@/app/i18n/client'

export function Header({ lng }: CommonLangProps) {
  const { t } = useTranslation(lng, 'navigation')
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const handleOpenMobileMenu = (newValue: boolean) => () =>
    setMobileMenuOpen(newValue)

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <div className="mx-auto max-w-7xl">
        <div className="px-6 pt-2 lg:max-w-2xl lg:pl-8 lg:pr-0">
          <nav
            className="flex items-center justify-between lg:justify-start"
            aria-label="Global"
          >
            <Link className="-m-4" href="#" aria-label="Home">
              <span className="sr-only">Speedhol</span>
              <Logo className="h-auto w-full min-w-[110px] max-lg:h-20 max-lg:w-auto" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-brand-stone-950 lg:hidden"
              onClick={handleOpenMobileMenu(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex hidden items-center lg:ml-12 lg:flex lg:gap-x-14">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-lg font-semibold leading-6 text-brand-stone-950"
                >
                  {t(item.i18nKey)}
                </a>
              ))}
              <div>
                <LanguageSwitcher lng={lng} />
              </div>
            </div>
          </nav>
        </div>
      </div>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={handleOpenMobileMenu(false)}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link className="-m-1.5 p-1.5" href="#" aria-label="Home">
              <span className="sr-only">Speedhol</span>
              <Logo className="h-16 w-auto" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-brand-stone-950"
              onClick={handleOpenMobileMenu(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    onClick={handleOpenMobileMenu(false)}
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-center text-base font-semibold leading-7 text-brand-stone-950 hover:bg-gray-50"
                  >
                    {t(item.i18nKey)}
                  </a>
                ))}
                <div className="flex w-full justify-center">
                  <LanguageSwitcher lng={lng} />
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
