import { AvailableLanguages, languages } from '@/app/i18n/settings'
import Link from 'next/link'
import { CommonLangProps } from '@/types/page'

type Props = {
  lng: AvailableLanguages
}

const iconsMap: { [key in AvailableLanguages]: any } = {
  pl: (
    <div className="flex h-5 w-5 items-center justify-center overflow-hidden rounded-full border border-brand-stone-200 shadow">
      <span
        style={{ backgroundSize: 'cover', lineHeight: '2em' }}
        className="fi fi-pl"
      ></span>{' '}
    </div>
  ),
  ua: (
    <div className="flex h-5 w-5 items-center justify-center overflow-hidden rounded-full border border-brand-stone-200 shadow">
      <span
        style={{ backgroundSize: 'cover', lineHeight: '2em' }}
        className="fi fi-ua"
      ></span>{' '}
    </div>
  ),
}

export const LanguageSwitcher = ({ lng }: CommonLangProps) => {
  return (
    <>
      {languages
        .filter((l) => lng !== l)
        .map((l, index) => {
          return (
            <span key={l}>
              <Link aria-label="zmiana języka" href={`/${l}`}>
                {iconsMap[l]}
              </Link>
            </span>
          )
        })}
    </>
  )
}
