export type AvailableLanguages = 'pl' | 'ua'
export const fallbackLng: AvailableLanguages = 'pl'
export const languages: AvailableLanguages[] = [fallbackLng, 'ua']
export const defaultNS = 'translation'
export const cookieName = 'i18next'

export function getOptions(lng = fallbackLng, ns = defaultNS) {
  return {
    // debug: true,
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  }
}
