'use client'

import Link from 'next/link'

import { Container } from '@/components/Container'
import { Logo } from '@/components/Logo'
import { NavLink } from '@/components/NavLink'
import { navigation } from '@/config/navigation'
import facebookIcon from '../../public/images/logos/facebook.svg'
import { useTranslation } from '@/app/i18n/client'
import { CommonLangProps } from '@/types/page'
import { FB_PAGE_URL } from '@/config/constants'

export function Footer({ lng }: CommonLangProps) {
  const { t } = useTranslation(lng, 'navigation')

  return (
    <footer className="bg-slate-50">
      <Container>
        <div className="py-16">
          <Logo className="mx-auto h-24 w-auto" />
          <nav className="mt-10 text-sm" aria-label="quick links">
            <div className="-my-1 flex justify-center gap-x-6">
              {navigation.map(({ name, href, i18nKey }) => (
                <NavLink key={name} href={href}>
                  {t(i18nKey)}
                </NavLink>
              ))}
            </div>
          </nav>
        </div>
        <div className="flex flex-col items-center border-t border-slate-400/10 py-10 sm:flex-row-reverse sm:justify-between">
          <div className="flex gap-x-6">
            <Link
              href={FB_PAGE_URL}
              target="_blank"
              className="group"
              aria-label="Speedhol na Facebooku"
            >
              <img alt="Facebook" src={facebookIcon.src} className="h-5" />
            </Link>
          </div>
          <p className="mt-6 text-sm text-slate-500 sm:mt-0">
            Speedhol &copy; {new Date().getFullYear()}
          </p>
        </div>
      </Container>
    </footer>
  )
}
